import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import Layout from "../../layouts/default"

import Breadcrumb from "../../components/breadcrumb"
import PageTitle from "../../components/page-title"
import Constrain from "../../components/constrain"
import Paragraph from "../../components/paragraph"
import Seo from "../../components/seo"
import Stack from "../../components/stack"
import VideoPlayer from "../../components/video-player"

const Katalog2 = () => {
  const data = useStaticQuery(graphql`
    query {
      poster: file(
        relativePath: { eq: "videos/poster-katalog-marstaller-01.jpg" }
      ) {
        ...largeImage
      }
    }
  `)
  return (
    <Layout backdrop="aussen">
      <Seo title="Der Turm zu Hölderlins Zeit" />
      <Stack space={[12, 24]}>
        <Stack>
          <Breadcrumb
            items={[
              {
                title: "Der Turm zu Hölderlins Zeit",
                link: "/k/2",
              },
            ]}
          />
          <PageTitle>Der Turm zu Hölderlins Zeit</PageTitle>
          <Constrain align="left">
            <Paragraph>
              Der Bauarchäologe Tilmann Marstaller im Interview
            </Paragraph>
          </Constrain>
          <VideoPlayer
            src="katalog/02-tilmann-marstaller"
            poster={data.poster}
          />
        </Stack>
      </Stack>
    </Layout>
  )
}

export default Katalog2
